<template>
  <v-container>
    <preloader v-if="status.getting" text="Please wait..." />
    <v-card v-if="!status.getting" max-width="850" class="mx-auto box-shadow" outlined>
      <v-card-title class="px-7 blue-grey lighten-5">
        <back />
        <span class="font-weight-bold">Customizing Recipe "{{ data.name }}"</span>
        <div v-if="!data.id" class="ml-3">
          <v-btn
            @click="startIntro()"
            x-small
            fab
            depressed
            color="info button-help"
          >
            <v-icon dark>
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pa-10">
        <v-alert v-if="status.added" type="success" text border="left" transition="fade">
          <div class="d-flex justify-space-between">
            Recipe "{{ data.name }}" has been customized, an email sent to the creator for approval. Thanks!.

            <div>
              <v-btn small class="mr-2" @click="$router.go(-1)">Go Back</v-btn>
            </div>
          </div>
        </v-alert>

        <v-form v-if="!status.added" ref="recipeForm" @submit.prevent="validateForm()">

          <v-alert
            v-if="status.error"
            border="left"
            type="error"
            class="mb-7"
          >{{ status.error }}</v-alert>

          <div v-if="data.photo && (typeof data.photo !== 'object')" class="mb-7">
            <v-card>
              <v-card-text class="pa-0">
                <v-btn 
                  @click="showDelete = true"
                  class="mt-2 mr-n2" 
                  absolute 
                  x-small 
                  right 
                  fab 
                  v-if="data.photo !== originalPhoto"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <RecipeImage :recipe="data" size="large" />
              </v-card-text>
            </v-card>
          </div>

          <!-- RECIPE NAME -->
          <div
            data-intro="Add the recipe name"
          >
            <v-text-field
              v-model="data.name"
              :rules="[rules.required]"
              label="Name"
              outlined
            ></v-text-field>
          </div>

          <!-- PHOTO UPLOAD -->
          <v-progress-linear
            v-if="status.uploadProgress"
            :value="status.uploadProgress"
            height="4"
            color="primary"
            class="mb-2"
            reactive
          ></v-progress-linear>

          <div
            data-intro="Add an image that displays the recipe (jpg or png)"
          >
            <v-file-input
              v-if="typeof(data.photo) == 'object' || !data.photo"
              accept="image/png, image/jpeg"
              prepend-inner-icon="mdi-image"
              v-model="data.photo"
              prepend-icon=""
              label="Photo"
              outlined
            ></v-file-input>
          </div>

          <!-- MEAL TIME -->
          <div
            data-intro="Choose the most suitable meal time for the recipe"
          >
            <v-select
              v-model="data.mealTime"
              label="Meal Time"
              :items="mealTime"
              :rules="[rules.required]"
              @change="checkNutritionTags()"
              prepend-inner-icon="mdi-clock"
              deletable-chips
              multiple
              outlined
              chips
            ></v-select>
          </div>

          <!-- INGREDIENTS -->
          <div class="mb-3 font-weight-bold primary--text">
            Ingredients
          </div>

          <v-card v-if="ingredients.length" class="mb-5" outlined>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Measure</th>
                    <th class="text-center">Quantity</th>
                    <th width="20px"></th>
                  </tr>
                </thead>
                <draggable v-model="draggableIngredients" tag="tbody">
                  <tr v-for="(ingredient) in draggableIngredients" :key="ingredient.id">
                    <td>
                      <div class="d-flex align-center">
                        <v-icon small class="mr-1">mdi-arrow-up-down-bold</v-icon>
                        <span>{{ ingredient.food.name }}</span>
                      </div>
                    </td>
                    <td>
                      <v-sheet max-width="250">
                        <v-select
                          v-model="ingredient.portion"
                          :items="ingredient.portions"
                          item-text="name"
                          item-value="id"
                          return-object
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-sheet>
                    </td>
                    <td>
                      <v-text-field
                        v-model="ingredient.quantity"
                        :style="{ width: '100px', margin: '0 auto' }"
                        type="number"
                        hide-details
                        outlined
                        min="0"
                        dense
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="toggleDeleteIngredients(ingredient)"
                        :loading="status.deletingIngredient.includes(ingredient)"
                        icon
                        small
                      >
                        <v-icon small>mdi-minus-circle-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-simple-table>
          </v-card>
          <v-alert v-else text border="left" type="info">
            Please add at least one ingredient.
          </v-alert>

          <!-- INSERT RECIPE FORM -->

          <v-form ref="insertRecipeForm">
            <div class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row align-center gap-10">
              <v-row dense
               data-intro="Add the ingredients"
              >
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model="newIngredient.food.name"
                    @click="showDialog()"
                    :rules="[rules.required]"
                    label="Ingredient Name"
                    hide-details
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-select
                    @change="$store.commit('recipe/setPortion', $event)"
                    :loading="status.gettingPortions"
                    :disabled="status.gettingPortions"
                    :rules="[rules.required]"
                    :items="foodPortions"
                    label="Measurement"
                    item-text="name"
                    return-object
                    hide-details
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4" xl="4">
                  <v-text-field
                    v-model.number="newIngredient.quantity"
                    :rules="[rules.required]"
                    label="Quantity"
                    type="number"
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                @click="insertRecipe()"
                :loading="status.addingIngredient"
                color="accent"
                depressed
                small
                fab
                data-intro="Add more ingredients as required"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-form>

          <v-checkbox v-if="user && user.role === 'admin'" label="Lock serve" v-model="data.lock_serve" hide-details></v-checkbox>

          <v-divider class="my-5"></v-divider>

          <!-- VIDEO URL -->

          <v-text-field
            v-model="data.video"
            label="Video URL"
            :rules="[rules.url]"
            prepend-inner-icon="mdi-link"
            outlined
          ></v-text-field>

          <v-row data-intro="Select the number of serves">
            <v-col cols="12" md="6" class="py-0">
              <!-- NUMBER OF SERVES -->
              <v-text-field
                v-model.number="data.numberOfServes"
                :value="1"
                label="Number of serves"
                type="number"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- GRAM PER SERVE -->
              <v-text-field
                :value="gramPerServe"
                label="Gram per serve"
                outlined
                suffix="g"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- DESCRIPTION -->
          <div
            data-intro="Add a description of the recipe"
          >
            <v-textarea
              v-model="data.description"
              label="Description"
              outlined
              auto-grow
            ></v-textarea>
          </div>

          <!-- METHODS -->

          <div class="mb-3 font-weight-bold primary--text">
            Methods
          </div>

          <v-alert v-if="!data.methods.length" type="info" border="left" text>
            Please add at least one method.
          </v-alert>

          <div v-else>
            <div
              v-for="(method, i) in data.methods"
              :key="i"
              class="d-flex gap-10"
            >
              <div>{{ i + 1 }}.</div>
              <v-textarea
                v-model="data.methods[i]"
                label="Method"
                outlined
                auto-grow
                data-intro="Add the method"
              ></v-textarea>
              
              <v-btn
                @click="toggleDeleteMethod(i)"
                icon
                small
                data-intro="Click the '+' to add the next method step"
              >
                <v-icon small>mdi-minus-circle-outline</v-icon>
              </v-btn>
            </div>
          </div>

          <div class="d-flex justify-center mb-7">
            <v-btn
              @click="toggleInsertMethod()"
              color="accent"
              depressed
              small
              fab
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>

          <!-- MEAL TAGS -->
          <div class="mb-3 font-weight-bold primary--text">
            Meal Tags
          </div>
          <div
            data-intro="Choose the approriate meal tags"
          >
            <v-select
              v-model="data.tags"
              :items="mealTags"
              item-text="name"
              item-value="id"
              deletable-chips
              small-chips
              multiple
              outlined
            ></v-select>
          </div>

          <!-- NUTRITION TAGS -->
          <div class="mb-3 font-weight-bold primary--text">
            Nutrition Tags
          </div>

          <div class="d-flex flex-wrap gap-10 align-center mb-7">
            <v-row no-gutters data-intro="Choose the appropriate nutrition tags">
              <v-col
                v-for="tag in nutritionTags"
                :key="tag.id"
                cols="12"
                md="6"
                lg="3"
                xl="3"
              >
                <v-checkbox
                  :readonly="!!tag.main || !!tag.snack || !!tag.hundred"
                  v-model="data.nutritionTags"
                  :label="tag.name"
                  :value="tag.id"
                  class="ma-0"
                  hide-details
                  multiple
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>

          <!-- ANALYSIS SUMMARY -->

          <div class="mb-3 font-weight-bold primary--text">
            Analysis Summary
          </div>

          <v-simple-table class="grey lighten-4" id="nutrient-table" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="33%"></th>
                  <th width="33%">Quantity Per Serving</th>
                  <th width="33%">Quantity Per 100g</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Weight (g)</td>
                  <td>{{ getTotalWeight | amount | numberFormat }}g</td>
                  <td>100.00g</td>
                </tr>
                <tr>
                  <td>Energy {{ user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }}</td>
                  <td v-if="user.energyUnit == 'cal'">{{ getTotalEnergy | kjToCal | amount | numberFormat }}Cal</td>
                  <td v-else>{{ getTotalEnergy | amount | numberFormat }}kJ</td>
                  <td v-if="user.energyUnit == 'cal'">{{ calcPerHundredGram(getTotalEnergy) | kjToCal }}Cal</td>
                  <td v-else>{{ calcPerHundredGram(getTotalEnergy) }}kJ</td>
                </tr>
                <tr>
                  <td>Protein (g)</td>
                  <td>{{ getTotalProtein | amount | numberFormat }}g</td>
                  <td>{{ calcPerHundredGram(getTotalProtein) }}g</td>
                </tr>
                <tr>
                  <td>Fat (g)</td>
                  <td>{{ getTotalFat | amount | numberFormat }}g</td>
                  <td>{{ calcPerHundredGram(getTotalFat) }}g</td>
                </tr>
                <tr>
                  <td>Carbohydrate (g)</td>
                  <td>{{ getTotalCarbohydrate | amount | numberFormat }}g</td>
                  <td>{{ calcPerHundredGram(getTotalCarbohydrate) }}g</td>
                </tr>
                <tr>
                  <td>Sugar (g)</td>
                  <td>{{ getTotalSugar  | amount | numberFormat }}g</td>
                  <td>{{ calcPerHundredGram(getTotalSugar) }}g</td>
                </tr>
                <tr>
                  <td>Fibre (g)</td>
                  <td>{{ getTotalFibre  | amount | numberFormat }}g</td>
                  <td>{{ calcPerHundredGram(getTotalFibre) }}g</td>
                </tr>
                <tr>
                  <td>Sodium (mg)</td>
                  <td>{{ getTotalSodium  | amount | numberFormat }}mg</td>
                  <td>{{ calcPerHundredGram(getTotalSodium) }}mg</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="mt-10 d-flex flex-wrap" style="gap: 1rem" v-if="$route.params.id">
            <v-btn
              :loading="status.adding"
              @click="validateForm()"
              color="primary"
              depressed
              data-intro="Click on ADD RECIPE"
            >
                Add Custom Recipe
            </v-btn>

            <span v-if="status.error || localError" class="error--text ml-5">An error has occured. Please check message/errors above.</span>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <FindIngredient
      @selected="selectedPortion"
      @cancel="closeDialog()"
      ref="findIngredient"
    />

    <ConfirmDelete
      message="Are you sure you want to delete this photo?"
      :deleting="status.deletingPhoto"
      @confirmed="deleteConfirmed()"
      @cancel="showDelete = false"
      :showDialog="showDelete"
    />
  </v-container>
</template>

<script>

import _ from 'lodash'
import rules from '@/rules'
import mealTime from '@/options'
import draggable from 'vuedraggable'
import RecipeImage from '@/components/RecipeImage'
import { mapState, mapGetters, mapActions } from 'vuex'
import FindIngredient from '@/components/FindIngredientDialog'

import IntroJs from 'intro.js'

export default {
  name: 'AddRecipe',

  metaInfo() {
    return {
      title: this.pageTitle
    }
  },

  components: {
    FindIngredient,
    RecipeImage,
    draggable
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      mealTime,
      show: false,
      recipe: null,
      portion: null,
      ingredient: null,
      localError: false,
      showDelete: false,
      pageTitle: 'Add New Recipe',
      tagLargeWeight: { mainAmount: 0 },
      tagSmallWeight: { mainAmount: 100000000 },
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      data: state => state.recipe.data,
      originalPhoto: state => state.recipe.originalData.photo,
      status: state => state.recipe.status,
      recipes: state => state.recipes.recipes,
      myRecipes: state => state.recipes.myRecipes,
      ings: state => state.ingredients.ingredients,
      ingredients: state => state.recipe.ingredients,
      foodPortions: state => state.recipe.foodPortions,
      newIngredient: state => state.recipe.newIngredient,
      mealTagsFromExcel: state => state.excel.mealTags,
    }),

    ...mapGetters('recipe', [
      'getTotalCarbohydrate',
      'getTotalProtein',
      'getTotalWeight',
      'getTotalEnergy',
      'getTotalSodium',
      'getTotalSugar',
      'getTotalFibre',
      'getTotalFat',
      
    ]),

    minerals: function () {
      return [
        { name: 'Carbohydrate', value: this.getTotalCarbohydrate},
        { name: 'Protein', value: this.getTotalProtein},
        { name: 'Energy', value: this.getTotalEnergy}, 
        { name: 'Sodium', value: this.getTotalSodium},
        { name: 'Weight', value: this.getTotalWeight},
        { name: 'Sugar', value: this.getTotalSugar},
        { name: 'Fibre', value: this.getTotalFibre},
        { name: 'Fat', value: this.getTotalFat},
      ]
    },

    gramPerServe: function () {
      return Math.round(this.getTotalWeight)
    },

    draggableIngredients: {
      get() {
        return  _.orderBy(this.ingredients, 'order', 'asc')
      },

      set(values) {
        this.$store.commit('recipe/updateIngredientOrder', values)
      }
    },

    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags']
    },

    mealTags: function () {
      return this.$store.getters['tags/tags']
    },
  },
  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'newAdded': function (value) {
      if (value) {
        this.selectedPortion()
      }
    },

    'ings': function () {
      if (this.data && this.data.id) {
        this.insertIngredients()
      }
    },

    'data': function (data) {
      if (data.ingredients && data.ingredients.length) {
        this.insertIngredients()
      }
    },

    'ingredients': function () {
      this.checkNutritionTags()
      this.checkMealTags()
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('recipe', [
      'addCustomizeRecipe',
      'addMyRecipe',
      'deletePhoto',
      'insertIngredient',
      'deleteIngredient'
    ]),
    
    ...mapActions('recipes', [
      'approveRecipe',
    ]),

    validateForm() {
      this.localError = false

      this.$store.commit('recipe/updateNutritionValues', {
        serving: {
          weight: parseFloat(this.getTotalWeight),
          energy: parseFloat(this.getTotalEnergy),
          protein: parseFloat(this.getTotalProtein),
          fat: parseFloat(this.getTotalFat),
          carbohydrate: parseFloat(this.getTotalCarbohydrate),
          sugar: parseFloat(this.getTotalSugar),
          fibre: parseFloat(this.getTotalFibre),
          sodium: parseFloat(this.getTotalSodium)
        },
        hundredGram: {
          energy: parseFloat(this.calcPerHundredGram(this.getTotalEnergy)),
          protein: parseFloat(this.calcPerHundredGram(this.getTotalProtein)),
          fat: parseFloat(this.calcPerHundredGram(this.getTotalFat)),
          carbohydrate: parseFloat(this.calcPerHundredGram(this.getTotalCarbohydrate)),
          sugar: parseFloat(this.calcPerHundredGram(this.getTotalSugar)),
          fibre: parseFloat(this.calcPerHundredGram(this.getTotalFibre)),
          sodium: parseFloat(this.calcPerHundredGram(this.getTotalSodium))
        }
      })

      if (this.$refs.recipeForm.validate()) {
        Promise.resolve(this.addCustomizeRecipe())
      }
      else {
        this.localError = true
      }
    },

    showDialog() {
      this.$refs.findIngredient.dialog = true
    },

    selectedPortion() {
      this.closeDialog()
    },

    deleteConfirmed() {
      Promise.resolve(this.deletePhoto())
      .then(() => {
        this.showDelete = false
      })
    },

    insertRecipe() {
      if (this.$refs.insertRecipeForm.validate()) {
        this.$store.commit('recipe/insertIngredient')
        this.$refs.insertRecipeForm.resetValidation()

        this.setMealTags()
        this.setNutritionTags()
      }
    },

    calcPerHundredGram(value) {
      let total =  value / this.data.numberOfServes
      let weight = this.gramPerServe / 100
      total = value / weight
      return this.$options.filters.numberFormat(total ? total.toFixed(2) : 0)
    },

    insertIngredients() {
      this.data.ingredients.forEach(ing => {
        let ingredient = this.ings.find(i => { 
          return i.ingredient.toLowerCase() == ing.ingredient.toLowerCase() && 
          i.portion.toLowerCase() == ing.portion.toLowerCase() 
        })
        
        if (ingredient) this.$store.commit('recipe/insertIngredient', ingredient)
      })
    },

    checkNutritionTags() {
      if (this.data.mealTime && this.data.mealTime.length && this.ingredients && this.ingredients.length) {
        this.nutritionTags.forEach(tag => {
          if (tag.main || tag.hundred || tag.snack) {
            this.minerals.forEach(mineral => {
              // MAIN MEALS NUTRITION TAGS
              if (_.intersection(['Breakfast', 'Lunch', 'Dinner'], this.data.mealTime).length && tag.main && tag.main.name == mineral.name) {
                if (tag.main && tag.main.name == 'Weight') {
                  if (tag.mainCondition == '≤') {
                    if (
                        (parseFloat(this.tagSmallWeight.mainAmount) >= parseFloat(tag.mainAmount) && parseFloat(tag.mainAmount) >= mineral.value) ||
                        (this.tagSmallWeight.id && mineral.value > this.tagSmallWeight.mainAmount && parseFloat(tag.mainAmount) >= mineral.value)
                    ) {
                      if (this.tagSmallWeight.id) this.$store.commit('recipe/removeNutritionTag', this.tagSmallWeight.id)
                      Object.assign(this.tagSmallWeight, tag)
                      this.$store.commit('recipe/insertNutritionTag', this.tagSmallWeight.id)
                    }
                    else {
                      this.$store.commit('recipe/removeNutritionTag', tag.id)
                    }
                  } 
                  else if (tag.mainCondition == '≥') {
                    if (
                      (parseFloat(tag.mainAmount) >= parseFloat(this.tagLargeWeight.mainAmount) && mineral.value >= parseFloat(tag.mainAmount)) ||
                      (this.tagLargeWeight.id && mineral.value < this.tagLargeWeight.mainAmount && parseFloat(tag.mainAmount) <= mineral.value)
                    ) {
                      if (this.tagLargeWeight.id) this.$store.commit('recipe/removeNutritionTag', this.tagLargeWeight.id)
                      
                      Object.assign(this.tagLargeWeight, tag)
                      this.$store.commit('recipe/insertNutritionTag', this.tagLargeWeight.id)
                    }
                    else {
                      this.$store.commit('recipe/removeNutritionTag', tag.id)
                    }
                  }
                  
                }
                else if ((tag.mainCondition == '≤' && mineral.value <= parseFloat(tag.mainAmount)) || 
                    (tag.mainCondition == '≥' && mineral.value >= parseFloat(tag.mainAmount))) {
                  this.$store.commit('recipe/insertNutritionTag', tag.id)
                }
                else {
                  this.$store.commit('recipe/removeNutritionTag', tag.id)
                }
              }
              
              // SNACK NUTRITION TAGS
              if (this.data.mealTime.includes('Snack') && tag.snack && tag.snack.name == mineral.name) {
                if ((tag.snackCondition == '≤' && mineral.value <= parseFloat(tag.snackAmount)) || 
                    (tag.snackCondition == '≥' && mineral.value >= parseFloat(tag.snackAmount))) {
                  this.$store.commit('recipe/insertNutritionTag', tag.id)
                }
                else {
                  this.$store.commit('recipe/removeNutritionTag', tag.id)
                }
              }
              
              // MEAL NUTRITION PER HUNDRED GRAM
              if (this.data.mealTime && this.data.mealTime.length && tag.hundred && tag.hundred.name == mineral.name) {
                if ((tag.hundredCondition == '≤' && this.calcPerHundredGram(mineral.value) <= parseFloat(tag.hundredAmount)) || 
                    (tag.hundredCondition == '≥' && this.calcPerHundredGram(mineral.value) >= parseFloat(tag.hundredAmount))) {
                  this.$store.commit('recipe/insertNutritionTag', tag.id)
                }
                else {
                  this.$store.commit('recipe/removeNutritionTag', tag.id)
                }
              }
            })
          }

          // NEW TAGS
          if(tag.switchToBool) {
            // GET ALL THE TAGS
            if(this.ingredients.length === this.data.ingredients.length) {
              console.log('-----------NUTRITION TAGS---------')
              const tags = this.ingredients.map(ing => ing.food[tag.mainBool])

              console.log(tag.name)
              console.log(tags)

              if(tags.every(t => t.toLowerCase() === tag.mainBoolVal.toLowerCase())){
                this.$store.commit('recipe/insertNutritionTag', tag.id)
              }
              else {
                this.$store.commit('recipe/removeNutritionTag', tag.id)
              }
            }
          }

        })

      }
    },

    checkMealTags () {
      if(this.ingredients.length === this.data.ingredients.length) {
      console.log('-----------MEAL TAGS---------')
        this.mealTags.forEach(tag => {
          if(this.mealTagsFromExcel.includes(tag.name)) {
            const tags = this.ingredients.map(ing => ing.food[tag.name])

            console.log(tag.name)
            console.log(tags)

            if(tags.every(t => t.toLowerCase() === 'yes')) {
              this.$store.commit('recipe/insertMealTag', tag.id)
            } else {
              this.$store.commit('recipe/removeMealTag', tag.id)
            }
          }
        })

        setTimeout(() => {
          // 6 ingredients or less Tag
          if(this.data.ingredients.length <= 6)
            this.$store.commit('recipe/insertMealTag', 'cVTWVj4Rj1IwlxuNy07L')
          else
            this.$store.commit('recipe/removeMealTag', 'cVTWVj4Rj1IwlxuNy07L')


          // Simple Tag
          if(this.data.ingredients.length <= 6 && this.data.methods.length <= 6) 
            this.$store.commit('recipe/insertMealTag', 'iTOmeAWBl7iC6y63pU3t')
          else
            this.$store.commit('recipe/removeMealTag', 'iTOmeAWBl7iC6y63pU3t')
        }, 2000);

      }
    },

    setMealTags () {
      console.log('-----------MEAL TAGS---------')
        this.mealTags.forEach(tag => {
          if(this.mealTagsFromExcel.includes(tag.name)) {
            const tags = this.ingredients.map(ing => ing.food[tag.name])

            console.log(tag.name)
            console.log(tags)

            if(tags.every(t => t.toLowerCase() === 'yes')) {
              this.$store.commit('recipe/insertMealTag', tag.id)
            } else {
              this.$store.commit('recipe/removeMealTag', tag.id)
            }
          }
        })

        setTimeout(() => {
          // 6 ingredients or less Tag
          if(this.ingredients.length <= 6)
            this.$store.commit('recipe/insertMealTag', 'cVTWVj4Rj1IwlxuNy07L')
          else
            this.$store.commit('recipe/removeMealTag', 'cVTWVj4Rj1IwlxuNy07L')


          // Simple Tag
          if(this.ingredients.length <= 6 && this.data.methods.length <= 6) 
            this.$store.commit('recipe/insertMealTag', 'iTOmeAWBl7iC6y63pU3t')
          else
            this.$store.commit('recipe/removeMealTag', 'iTOmeAWBl7iC6y63pU3t')
        }, 1500);

    },

    setNutritionTags() {
        console.log('-----------NUTRITION TAGS---------')

        setTimeout(() => {
          this.nutritionTags.forEach((tag) => {
            if(tag.switchToBool) {
              const tags = this.ingredients.map(ing => ing.food[tag.mainBool])

              console.log(tag.name)
              console.log(tags)

              if(tags.every(t => t.toLowerCase() === tag.mainBoolVal.toLowerCase())){
                this.$store.commit('recipe/insertNutritionTag', tag.id)
              }
              else {
                this.$store.commit('recipe/removeNutritionTag', tag.id)
              }
            }
          })
        }, 1500)
    },

    startIntro () {
      const introJs = IntroJs()

      introJs.setOptions({
        disableInteraction: true,
      }).start()
    },

    firstTimeUser() {
      const introJs = IntroJs()

      setTimeout(() => {
        introJs.setOptions({
          steps: [
            {
              title: 'Guide',
              element: document.querySelector('.button-help'),
              intro: "Click on this button to launch the demo on how to add a recipe"
            }
          ],
          disableInteraction: true,
          doneLabel: 'Got it',
          showBullets: false,
          showProgress: false,
          dontShowAgain: true
        }).start()
      }, 300)
    },

    toggleDeleteIngredients(ing) {
      this.deleteIngredient(ing)
      this.setMealTags()
      this.setNutritionTags()
    },

    toggleDeleteMethod(i) {
      this.$store.commit('recipe/removeMethod', i)
      this.setMealTags()
    },

    toggleInsertMethod () {
      this.$store.commit('recipe/insertMethod', null)
      this.method = null
      this.setMealTags()
    },
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch('tags/getTags')
    if (!this.$store.state.nutritiontags.status.firstLoad) this.$store.dispatch('nutritiontags/getTags')

    if (this.$route.params.id) {
      // this.$store.commit('recipe/resetState')
      // this.$store.dispatch('recipe/getRecipe', this.$route.params.id)
      // .then(res => {
      //   this.$store.commit('recipe/setOriginalData', res)
      //   this.$store.commit('recipe/resetDataPhotos')
      // })
      this.pageTitle = 'Customizing Recipe'
    }

    !this.data.id && this.firstTimeUser()
  }
}

</script>

<style>

#nutrient-table tr th:not(:first-child),
#nutrient-table tr td:not(:first-child) {
  text-align: right;
}

#nutrient-table tr td:first-child {
  font-weight: bold;
}

</style>
